import React, { useRef } from 'react';

export function SideWalls(props) {
  const mesh = useRef();

  return (
    <mesh {...props} ref={mesh}>
      <boxGeometry args={[props.x, props.y, props.z]} /> {/* Use boxGeometry */}
      <meshStandardMaterial color="white" />
    </mesh>
  );
}
