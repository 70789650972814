import React from 'react';
import { usePlane } from '@react-three/cannon';

export function Plane({ width = 100, height = 100, color = '#8a8888', ...props }) {
  const [ref] = usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0], // Plane lies flat on the XZ plane
    ...props, // Allows additional physics properties
  }));

  return (
    <mesh ref={ref} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
      <planeGeometry args={[width, height]} /> {/* Updated from planeBufferGeometry */}
      <meshLambertMaterial color={color} />
    </mesh>
  );
}
