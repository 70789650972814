import React, { useRef, useState } from 'react';

export function Floor(props) {
  const mesh = useRef();
  const [active, setActive] = useState(false);

  return (
    <mesh {...props} ref={mesh} onClick={() => setActive(!active)}>
      <boxGeometry args={[props.x, props.y, props.z]} /> {/* Use boxGeometry */}
      <meshStandardMaterial color={active ? '#ffcc00' : '#A47528'} />
    </mesh>
  );
}